<template>
  <div>
    <div style="margin-left: 10px;display: flex;color: white;">
      <img :src="teamImage" width="30" height="30" alt="">
      <div style="font-size: 16px;font-weight: bold;margin-left: 22px;line-height: 30px">{{ teamName }}</div>
      <i @click="handleRefresh" style="cursor: pointer;font-size: 16px;;margin-left: 22px;line-height: 30px" class="el-icon-refresh-right"></i>
    </div>
    <el-table @row-contextmenu="tableRightClick" :height="getTableHeight" :data="playerList.filter(data => !keyword || data['personaName'].toLowerCase().includes(keyword.toLowerCase()))" v-loading="loading" element-loading-background="#111111" empty-text="暂无玩家" ref="delayedPlayerListTable" :header-cell-style="{color: '#FFFFFF',height: '50px'}" :cell-style="{color: '#FFFFFF', padding: '2px', height: '32px'}">
      <el-table-column style="color: rgb(244,230,225);" class-name="nonePadding" label="序号" type="index" width="51" align="center"/>
      <el-table-column class-name="nonePadding" label="等级" width="50" align="center">
        <template #header>
          <div @click="sort('rank')" :style="{'color': sortBy === 'rank' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            等级
          </div>
        </template>
        <template v-slot="scope">
          <div style="border: 1px solid #DEDEDEFF;width: 28px;height: 16px;font-size: 13px;line-height: 13px" :style="{'background-color': scope['row']['rank'] === 150 ? 'rgb(222,222,222)' : 'transparent', color: scope['row']['rank'] === 150 ? 'black' : 'rgb(222,222,222)'}">
            {{ scope['row']['rank'] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column class-name="nonePadding" prop="personaName" label="ID" min-width="200">
        <template #header>
          <el-input style="max-width: 200px;" class="transparentElement" v-model="keyword" size="mini" placeholder="输入关键字搜索"/>
        </template>
        <template v-slot="scope">
          <div style="line-height: 30px">
            <a-icon style="color: #faad14;cursor: pointer" type="search" @click="navigateToPersonaDetail(scope['row']['personaId'])"/>
            <span v-if="scope['row']['platoonName']" style="color: #81b0fc;">
              [ {{ scope['row']['platoonName'] }} ]
            </span>
            <span v-if="scope['row']['role'] === 3 || scope['row']['role'] === 4" style="color: rgb(252,118,118);">
              {{ scope['row']['personaName'] }}
            </span>
            <span v-if="scope['row']['role'] === 2" style="color: rgb(255,255,102);">
              {{ scope['row']['personaName'] }}
            </span>
            <span v-if="scope['row']['role'] === 1" style="color: rgb(222,222,222)">
              {{ scope['row']['personaName'] }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="detailed" class-name="nonePadding" label="KD" prop="detailed.kd" width="60" :formatter="(row, column) => row.detailed?.kd.toFixed(2)">
        <template #header>
          <div @click="sort('kd')" :style="{'color': sortBy === 'kd' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            KD
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="detailed" class-name="nonePadding" label="KPM" prop="detailed.kpm" width="60" :formatter="(row, column) => row.detailed?.kpm.toFixed(2)">
        <template #header>
          <div @click="sort('kpm')" :style="{'color': sortBy === 'kpm' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            KPM
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="detailed" class-name="nonePadding" label="爆头率" prop="detailed.headShotsRatio" width="70" :formatter="(row, column) => row.detailed?.headShotsRatio.toFixed(2) + '%'">
        <template #header>
          <div @click="sort('headShotsRatio')" :style="{'color': sortBy === 'headShotsRatio' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            爆头率
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="detailed" class-name="nonePadding" label="命中率" prop="detailed.accuracyRatio" width="70" :formatter="(row, column) => row.detailed?.accuracyRatio.toFixed(2) + '%'">
        <template #header>
          <div @click="sort('accuracyRatio')" :style="{'color': sortBy === 'accuracyRatio' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            命中率
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="detailed" class-name="nonePadding" label="胜率" prop="detailed.winRatio" width="70" :formatter="(row, column) => row.detailed?.winRatio.toFixed(2) + '%'">
        <template #header>
          <div @click="sort('winRatio')" :style="{'color': sortBy === 'winRatio' ? 'rgb(204,255,102)' : 'white'}" style="cursor: pointer">
            胜率
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="!detailed" width="140" align="center">
        <template v-slot="scope">
          <a-row>
            <a-col :span="8">
              <el-link style="line-height: 25px" :disabled="scope['row']['role'] === 3 || scope['row']['role'] === 4 || !hasPermission || processing" :type="(scope['row']['role'] === 2 || scope['row']['role'] === 1) ? 'warning' : 'info'" @click="handleKick(scope['row'])">踢出</el-link>
            </a-col>
            <a-col :span="8">
              <el-link :disabled="scope['row']['role'] === 3 || scope['row']['role'] === 4 || !hasPermission || processing" :type="(scope['row']['role'] === 2 || scope['row']['role'] === 1) ? 'danger' : 'info'" @click="handleBan(scope['row'])">封禁</el-link>
            </a-col>
            <a-col :span="8">
              <el-link :disabled="!hasPermission || processing" type="primary" @click="handleMove(scope['row'])">换边</el-link>
            </a-col>
          </a-row>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "playerListTable",
  data() {
    return {
      keyword: "",
      sortBy: "rank",
    }
  },
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    detailed: {
      type: Boolean,
      default: false
    },
    teamName: {
      type: String,
      default: ""
    },
    teamImage: {
      type: String,
      default: ""
    },
    playerList: {
      type: Array,
      default: () => []
    },
    processing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTableHeight() {
      let height = window.screen.height;
      if (height > 1080) {
        return "75vh"
      }
      else {
        return "70vh"
      }
    },
  },
  methods: {
    tableRightClick(row, column, event) {
      event.preventDefault();
      this.$contextmenu({
        items: [
          {
            label: "玩家 " + row['personaName'],
            icon: "el-icon-user",
            onClick: () => {
              this.navigateToPersonaDetail(row['personaId'])
            },
          },
          {
            label: "踢出",
            icon: "el-icon-right",
            disabled: !this.hasPermission || row['role'] === 3 || row['role'] === 4,
            onClick: () => {
              this.handleKick(row)
            },
          },
          {
            label: "封禁",
            icon: "el-icon-circle-close",
            disabled: !this.hasPermission || row['role'] === 3 || row['role'] === 4,
            onClick: () => {
              this.handleBan(row)
            },
          },
          {
            label: "移动",
            icon: "el-icon-refresh",
            disabled: !this.hasPermission || row['role'] === 3 || row['role'] === 4,
            onClick: () => {
              this.handleMove(row)
            },
          },
        ],
        event,
        customClass: "right-menu",
        zIndex: 99999999
      })
    },
    handleKick(player) {
      this.$emit("kick", player)
    },
    handleBan(player) {
      this.$emit("ban", player)
    },
    handleMove(player) {
      this.$emit("move", player)
    },
    handleRefresh() {
      this.$emit("refresh", this.detailed)
    },
    navigateToPersonaDetail(personaId) {
      let routeUrl = this.$router.resolve({
        path: "/player/info",
        query: {
          personaId: personaId,
          from: 3
        }
      });
      window.open(routeUrl .href, '_blank');
    },
    sort(sortBy) {
      this.sortBy = sortBy
      if (this.sortBy === 'rank') {
        this.playerList.sort((o1, o2) => {
          if (o1['rank'] === null || o1['rank'] === undefined) return 1;
          if (o2['rank'] === null || o2['rank'] === undefined) return -1;
          if (o1['rank'] > o2['rank']) return -1;
          else if (o1['rank'] < o2['rank']) return 1;
          else return 0;
        })
      }
      else {
        this.playerList.sort(this.buildDetailedCompare(this.sortBy))
      }
    },
    buildDetailedCompare(sortBy) {
      return (o1, o2) => {
        if (o1['detailed'] === null || o1['detailed'] === undefined) return 1;
        if (o2['detailed'] === null || o2['detailed'] === undefined) return -1;
        if (o1['detailed'][sortBy] === null || o1['detailed'][sortBy] === undefined) return 1;
        if (o2['detailed'][sortBy] === null || o2['detailed'][sortBy] === undefined) return -1;
        if (o1['detailed'][sortBy] > o2['detailed'][sortBy]) return -1;
        else if (o1['detailed'][sortBy] < o2['detailed'][sortBy]) return 1;
        else return 0;
      }
    }
  }
}
</script>

<style scoped>
/* 透明表格 */
::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
/* 透明表格 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}
/* 表格滚动条 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 表格滚动条 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
/* 表格鼠标移入 */
::v-deep .el-table tbody tr:hover>td {
  background: rgba(255, 255, 255, 0.2) !important;
}

::v-deep .nonePadding {
  padding: 0 !important;
}
</style>

<style>
.right-menu {
  background-color: #343434 !important;
  padding: 5px !important;
  color: white !important;
}
.right-menu .menu_item__available {
  color: white !important;
}
.right-menu .menu_item__disabled {
  color: rgba(255, 255, 255, 0.2) !important;
}
.right-menu .menu_item__available:hover,
.right-menu .menu_item_expand {
  background: rgba(224, 220, 220, 0.2) !important;
}
</style>