<template>
  <div v-loading="serverInfoLoading" element-loading-background="rgba(0, 0, 0, 0)" style="line-height: 1.5;height: 100%;">
    <a-row type="flex" justify="center" style="padding-top: 10px">
      <a-col :span="20">
        <el-page-header @back="handleBack" style="color: white;margin-bottom: 14px">
          <template #title>
            <div style="font-size: 20px">
              返回
            </div>
          </template>
          <template #content>
            <div style="color: white;font-size: 20px">
              服务器详情
            </div>
          </template>
        </el-page-header>
        <a-row :gutter="[0, 20]" style="color: white;margin-top: 10px">
          <a-col :xs="24" :sm="24" :md="24" :lg="15" :xl="16" :xxl="17">
            <div style="font-size: 30px;word-wrap: break-word">{{ objectUtil.nullToDefault(serverInfo['name'], "") }}</div>
            <div style="font-size: 18px;margin-top: 10px; white-space: pre-wrap">{{ objectUtil.nullToDefault(serverInfo['description'], "") }}</div>
          </a-col>

          <a-col v-if="serverInfo['owner'] != null" :xs="24" :sm="24" :md="24" :lg="{ span: 8, offset: 1 }" :xl="{ span: 7, offset: 1 }" :xxl="{ span: 5, offset: 2 }">
            <div @click="navigateToPersonaDetail" style="cursor: pointer">
              <a-row class="whiteBackgroundHover" style="display: flex;justify-content: space-between;padding: 10px;">
                <a-col :span="18">
                  <div style="font-size: 18px;">提供者</div>
                  <div style="font-size: 22px;">{{ objectUtil.nullToDefault(serverInfo['owner']['personaName'], "—") }}</div>
                </a-col>
                <a-col :span="6">
                  <div style="display: flex;justify-content: flex-end">
                    <img style="width: 60px; height: 60px" :src="serverInfo['owner']['personaAvatar'] ? serverInfo['owner']['personaAvatar'] : DefaultValue.defaultAvatar" alt="">
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>

        <a-row style="margin-top: 20px;color: white" :gutter="[30,30]">
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">类型</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(Bf1ServerType[serverInfo['type']], "—") }}<span v-if="serverInfo['isCustom']"> - </span><span v-if="serverInfo['isCustom']" style="color: #FFD700">{{ "自订" }}</span></div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">地区</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(Bf1Country[serverInfo['country']], "—") }}</div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">模式</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(serverInfo['mode']['name'], "—") }}</div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">地图</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(serverInfo['map']['name'], "—") }}</div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">玩家</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(serverInfo['slots']['soldier']['current'], "—") + '/' + objectUtil.nullToDefault(serverInfo['slots']['soldier']['max'], "—") + (serverInfo['slots']['queue']['current'] > 0 ? '[' + serverInfo['slots']['queue']['current'] + ']' : '') + (serverInfo['slots']['spectator']['current'] > 0 ? '(' + serverInfo['slots']['spectator']['current'] + ')' : '') }}</div>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" :xxl="4">
            <div style="font-size: 18px;text-align: center">收藏</div>
            <div style="font-size: 22px;text-align: center">{{ objectUtil.nullToDefault(serverInfo['bookmarkCount'], "—") }}</div>
          </a-col>
        </a-row>

        <a-row :gutter="[10,10]" style="margin-top: 20px">
          <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" style="display: flex;justify-content: center">
            <div style="width: 97%">
              <a-button :disabled="serverInfoLoading" size="large" class="hoverButton" ghost block @click="refreshServerInfo">刷新数据</a-button>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" style="display: flex;justify-content: center">
            <div style="width: 97%">
              <a-button :disabled="serverInfoLoading" size="large" class="hoverButton" ghost block @click="displayManagePanel">管理面板</a-button>
            </div>
          </a-col>
          <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" style="display: flex;justify-content: center">
            <div style="width: 97%">
              <a-button :disabled="serverInfoLoading" size="large" class="hoverButton" ghost block @click="displayPlayerListDialog">玩家列表</a-button>
            </div>
          </a-col>
        </a-row>

        <a-row v-if="serverInfo['isCustom']" style="margin-top: 20px;color: white;">
          <a-row>
            <div style="font-size: 18px">服务器设置</div>
            <div  style="display: flex;margin-top: 20px">
              <div v-for="(value, key) in customSettings" style="margin-right: 30px; width: 200px;">
                <div style="cursor: pointer" @click="displayCustom(key)"><el-divider>{{settingNameMap[key]}}</el-divider></div>
                <el-collapse-transition>
                  <div v-if="customDisplay">
                    <div v-for="(setting, index) in value" :key="index">
                      {{ setting['key'] }} : {{ setting['value'] === true ? '是' : setting['value'] === false ? '否' : setting['value'] }}
                      <el-divider></el-divider>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
          </a-row>
        </a-row>

        <a-row  v-if="serverInfo['rotations'] !== null" style="margin-top: 20px;color: white;">
          <a-row>
            <div style="font-size: 18px">地图轮换</div>
          </a-row>
          <a-row style="margin-top: 5px;overflow: auto" :gutter="[30,30]">
            <a-col class="mapItem" :xs="12" :sm="12" :md="8" :lg="8" :xl="6" :xxl="4" v-for="(value, index) in serverInfo['rotations']" :key="index">
              <div style="position: relative;overflow: hidden" >
                <img class="mapImage" width="100%" height="100%" :src="value['map']['url']"  alt=""/>
                <div :class="value['activated'] ? 'mapContentHover' : 'mapContent'">
                  <div style="height: 100%;width: 100%;display: flex;flex-direction: column;justify-content: center;padding-left: 30px">
                    <div>
                      {{ value['mode']['name'] }}
                    </div>
                    <div>
                      {{ value['map']['name'] }}
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>

    <el-dialog custom-class="manager-dialog" title="管理面板" :visible.sync="managePanelVisible" center top="0" :show-close="false">

      <el-dialog style="margin-top: 60px" :title="type[targetType]?.title + '确认'" :visible.sync="confirmFormVisible" width="400px" center :show-close="false" append-to-body>
        <a-descriptions :column="2">
          <a-descriptions-item v-if="targetPersonaRank != null" label="等级">{{ targetPersonaRank }}</a-descriptions-item>
          <a-descriptions-item v-if="targetPersonaName != null" label="玩家ID">{{ targetPersonaName }}</a-descriptions-item>
          <a-descriptions-item v-if="targetPersonaId != null" label="数字ID">{{ targetPersonaId }}</a-descriptions-item>
        </a-descriptions>
        <el-input style="margin-bottom: 20px" v-if="targetType === 'KICK' || targetType === 'ADD_BAN'" :disabled="processing" class="transparentElement" maxlength="10" show-word-limit v-model="targetReason" placeholder="请输入原因"/>
        <a-button :disabled="processing" style="height: 40px;" size="large" class="hoverButton" @click="handleTarget" ghost block>确认</a-button>
      </el-dialog>

      <a-tabs default-active-key="1">
        <a-tab-pane force-render key="1" tab="玩家列表">
          <player-list
              :has-permission="hasPermission"
              :team-one-name="teamOneName"
              :team-one-image="teamOneImage"
              :team-two-name="teamTwoName"
              :team-two-image="teamTwoImage"
              :team-one-player-list="teamOnePlayerList"
              :team-two-player-list="teamTwoPlayerList"
              :processing="processing"
              :loading="playerListLoading"
              @kick="(player) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], null, 'KICK')"
              @ban="(player) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], null, 'ADD_BAN')"
              @move="(player, teamId) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], teamId, 'MOVE')"
              @refresh="refreshPlayerList"
          />
        </a-tab-pane>

        <a-tab-pane force-render key="2" tab="封禁列表">
          <member-list
              name="封禁"
              :has-permission="hasPermission"
              :member-list="banList"
              :loading="banListLoading"
              :processing="processing"
              @refresh="refreshBanList"
              @remove="(member) =>  displayConfirmDialog(null, member['personaName'], member['personaId'], null, 'REMOVE_BAN')"
              @add="(personaName) => displayConfirmDialog(null, personaName, null, null, 'ADD_BAN')"
          />
        </a-tab-pane>

        <a-tab-pane force-render key="3" tab="VIP列表">
          <member-list
              name="VIP"
              :has-permission="hasPermission"
              :member-list="vipList"
              :loading="vipListLoading"
              :processing="processing"
              @refresh="refreshVipList"
              @remove="(member) => displayConfirmDialog(null, member['personaName'], member['personaId'], null, 'REMOVE_VIP')"
              @add="(personaName) => displayConfirmDialog(null, personaName, null, null, 'ADD_VIP')"
          />
        </a-tab-pane>

        <a-tab-pane force-render key="4" tab="管理列表">
          <member-list
              name="管理"
              :has-permission="hasPermission"
              :member-list="adminList"
              :loading="adminListLoading"
              :processing="processing"
              @refresh="refreshAdminList"
              :disable-remove="true"
              :disable-input="true"
              :disable-button="true"
          />
        </a-tab-pane>

        <a-tab-pane force-render key="5" tab="切换地图">
          <div v-loading="processing" element-loading-text="切图中..." element-loading-background="#111111" class="changeMapList" :style="{overflow: processing ? 'hidden' : 'auto'}" style="margin-top: 10px;height: 70vh;padding-left: 10px;padding-right: 10px">
            <a-row v-if="serverInfo['rotations'] !== null" :gutter="[20,30]">
              <a-col class="mapItem" :xs="24" :sm="12" :md="8" :lg="8" :xl="6" :xxl="6" v-for="(value, index) in serverInfo['rotations']" :key="index">
                <div style="position: relative;overflow: hidden" >
                  <img class="mapImage" width="100%" height="100%" :src="value['map']['url']"  alt=""/>
                  <div class="mapContent" style="height: 60%;opacity: 0.9">
                    <div style="height: 60%;width: 100%;display: flex;flex-direction: column;justify-content: center;padding-left: 30px">
                      <div>
                        {{ value['mode']['name'] }}
                      </div>
                      <div>
                        {{ value['map']['name'] }}
                      </div>
                    </div>
                    <div :style="{cursor: hasPermission ? 'pointer' : 'not-allowed'}" @click="mapSwitch(index)" style="height: 40%;border-top: 1px solid gray;display: flex;justify-content: center;align-items: center;">
                      变更地图
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-tab-pane>
      </a-tabs>
      <span slot="footer" class="dialog-footer">
        <span @click="managePanelVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">确 定</span>
      </span>
    </el-dialog>

    <el-dialog custom-class="manager-dialog" title="玩家列表" :visible.sync="playerListVisible" width="85%" center top="0" :show-close="false">

      <el-dialog style="margin-top: 60px" :title="type[targetType]?.title + '确认'" :visible.sync="confirmFormVisible" width="400px" center :show-close="false" append-to-body>
        <a-descriptions :column="2">
          <a-descriptions-item v-if="targetPersonaRank != null" label="等级">{{ targetPersonaRank }}</a-descriptions-item>
          <a-descriptions-item v-if="targetPersonaName != null" label="玩家ID">{{ targetPersonaName }}</a-descriptions-item>
          <a-descriptions-item v-if="targetPersonaId != null" label="数字ID">{{ targetPersonaId }}</a-descriptions-item>
        </a-descriptions>
        <el-input style="margin-bottom: 20px" v-if="targetType === 'KICK' || targetType === 'ADD_BAN'" :disabled="processing" class="transparentElement" maxlength="10" show-word-limit v-model="targetReason" placeholder="请输入原因"/>
        <a-button :disabled="processing" style="height: 40px;" size="large" class="hoverButton" @click="handleTarget" ghost block>确认</a-button>
      </el-dialog>

      <player-list
          :has-permission="hasPermission"
          :detailed="true"
          :team-one-name="teamOneName"
          :team-one-image="teamOneImage"
          :team-two-name="teamTwoName"
          :team-two-image="teamTwoImage"
          :team-one-player-list="teamOnePlayerList"
          :team-two-player-list="teamTwoPlayerList"
          :processing="processing"
          :loading="playerListLoading"
          @kick="(player) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], null, 'KICK')"
          @ban="(player) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], null, 'ADD_BAN')"
          @move="(player, teamId) => displayConfirmDialog(player['rank'], player['personaName'], player['personaId'], teamId, 'MOVE')"
          @refresh="refreshPlayerList"
      />
      <span slot="footer" class="dialog-footer">
        <span @click="managePanelVisible = false" style="color: white;cursor: pointer;font-size: 18px;border-bottom: 1px solid white;padding-bottom: 5px;padding-left: 10px;padding-right: 10px">确 定</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {queryServerApi} from "../../../request/api/server";
import {DefaultValue} from "../../../enum/default-value";
import {Bf1Country, Bf1Map, Bf1ServerType, BF1Settings} from "../../../enum/bf1-enum";
import {getLoggedIn} from "../../../utils/local-storage";
import {containGroupServerApi} from "../../../request/api/group-server";
import {
  batchQueryServerAdminApi,
  batchQueryServerBanApi, batchQueryServerPlayerApi,
  batchQueryServerVipApi
} from "../../../request/api/server-member";
import PlayerList from "../../../component/PlayerList.vue";
import MemberList from "../../../component/MemberList.vue";
import {
  addPlayerBanApi,
  addPlayerVipApi, kickPlayerApi, movePlayerApi,
  removePlayerBanApi, removePlayerVipApi,
  switchMapApi
} from "../../../request/api/server-management";
import {objectUtil} from "../../../utils/object-util";

export default {
  name: "serverInfo",
  computed: {
    objectUtil() {
      return objectUtil
    }
  },
  components: {MemberList, PlayerList},
  data() {
    return {
      Bf1Map: Bf1Map,
      Bf1Country: Bf1Country,
      Bf1ServerType: Bf1ServerType,
      DefaultValue: DefaultValue,
      hasPermission: false,
      settingNameMap: {
        kits: "兵种",
        misc: '杂项',
        scales: '比例',
        vehicles: '载具',
        weapons: '武器'
      },
      type: {
        'KICK': {
          title: '踢出玩家',
          func: this.kick
        },
        'ADD_BAN' : {
          title: '添加BAN',
          func: this.addBan
        },
        'REMOVE_BAN' : {
          title: '移除BAN',
          func: this.removeBan
        },
        'ADD_VIP': {
          title: '添加VIP',
          func: this.addVip
        },
        'REMOVE_VIP': {
          title: '移除VIP',
          func: this.removeVip
        },
        'MOVE': {
          title: '切换阵营',
          func: this.move
        }
      },
      gameId: -1,
      playerListLoading: false,
      serverInfoLoading: true,
      serverMemberLoading: false,
      adminListLoading: false,
      vipListLoading: false,
      banListLoading: false,
      processing: false,
      serverInfo: {
        gameId: null,
        guid: null,
        region: null,
        country: null,
        name: null,
        description: null,
        slots: {
          soldier: {
            current: null,
            max: null,
          },
          queue: {
            current: null,
            max: null,
          },
          spectator: {
            current: null,
            max: null,
          }
        },
        map: {
          code: null,
          name: null,
          url: null,
        },
        mode: {
          code: null,
          name: null,
        },
        isProtected: false,
        isCustom: false,
        type: null,
        settings: {
          misc: {},
          scales: {},
          weapons: {},
          vehicles: {},
          kits: {}
        },
        rotations: [],
        bookmarkCount: null,
        owner: null,
        createdDate: null,
        expirationDate: null,
      },
      teamOneName: "",
      teamOneImage: "",
      teamTwoName: "",
      teamTwoImage: "",
      teamOnePlayerList: [],
      teamTwoPlayerList: [],
      banList:[],
      vipList:[],
      adminList:[],
      managePanelVisible: false,
      playerListVisible: false,
      confirmFormVisible: false,
      targetPersonaRank: null,
      targetPersonaName: null,
      targetPersonaId: null,
      targetTeamId: null,
      targetType: null,
      targetReason: null,
      customSettings: {},
      customDisplay: false,
      playerListInitialized: false,
      memberListInitialized: false,
    }
  },
  created() {
    if (this.$route['query']['gameId']) {
      this.gameId = this.$route['query']['gameId']
    }
    if (this.$route['query']['from']) {
      this.$emit('changeKey', this.$route['query']['from'])
    }
    this.refreshGroupServerContain()
    this.refreshServerInfo()
  },
  watch: {
    '$route' (to) {
      this.managePanelVisible = false;
      this.playerListVisible = false;
      this.confirmFormVisible = false;
      if (to['path'] === '/server/info') {
        if (to['query']['gameId']) {
          this.gameId = to['query']['gameId']
        }
        this.refreshGroupServerContain()
        this.refreshServerInfo()
      }
    }
  },
  methods: {
    navigateToPersonaDetail() {
      if (this.serverInfo['owner'] === null) {
        return
      }
      let routeUrl = this.$router.resolve({
        path: "/player/info",
        query: {
          personaId: this.serverInfo['owner']['personaId'],
          from: 3
        }
      });
      window.open(routeUrl .href, '_blank');
    },
    displayCustom() {
      this.customDisplay = !this.customDisplay
    },
    refreshGroupServerContain() {
      if (this.gameId === -1) {
        return;
      }
      if (!getLoggedIn()) {
        return;
      }
      containGroupServerApi(this.gameId).then((res) => {
        this.hasPermission = res['data']['data']['contain']
      })
    },
    refreshServerInfo() {
      if (this.gameId === -1) {
        return;
      }
      this.serverInfoLoading = true;
      queryServerApi(this.gameId, null).then((res) => {
        let serverInfo = res['data']['data'];
        if (serverInfo['rotations']) {
          let hasActivated = false;
          for (let i = 0; i < serverInfo['rotations'].length; i++) {
            let rotation = serverInfo['rotations'][i];
            if (serverInfo['map']['name'] === rotation['map']['name'] && serverInfo['mode']['name'] === rotation['mode']['name'] && !hasActivated) {
              rotation['activated'] = true;
              hasActivated = true;
            }
            else {
              rotation['activated'] = false;
            }
          }
          if (serverInfo['isCustom']) {
            let kits = serverInfo['settings']['kits'];
            let misc = serverInfo['settings']['misc'];
            let scales = serverInfo['settings']['scales'];
            let vehicles = serverInfo['settings']['vehicles'];
            let weapons = serverInfo['settings']['weapons'];

            let kitsDiffKeyArr = this.getDiffArray(BF1Settings.kits, kits);
            let miscDiffKeyArr = this.getDiffArray(BF1Settings.misc, misc);
            let scalesDiffKeyArr = this.getDiffArray(BF1Settings.scales, scales);
            let vehiclesDiffKeyArr = this.getDiffArray(BF1Settings.vehicles, vehicles);
            let weaponsDiffKeyArr = this.getDiffArray(BF1Settings.weapons, weapons);

            this.putCustomArray(kitsDiffKeyArr, "kits", kits)
            this.putCustomArray(miscDiffKeyArr, "misc", misc)
            this.putCustomArray(scalesDiffKeyArr, "scales", scales)
            this.putCustomArray(vehiclesDiffKeyArr, "vehicles", vehicles)
            this.putCustomArray(weaponsDiffKeyArr, "weapons", weapons)
          }
        }
        this.serverInfo = serverInfo;
        this.teamOneName = Bf1Map[this.serverInfo['map']['code']]?.['teamOne']['name']
        this.teamOneImage = Bf1Map[this.serverInfo['map']['code']]?.['teamOne']['image']
        this.teamTwoName = Bf1Map[this.serverInfo['map']['code']]?.['teamTwo']['name']
        this.teamTwoImage = Bf1Map[this.serverInfo['map']['code']]?.['teamTwo']['image']
      }).finally(()=>{
        this.serverInfoLoading = false;
      })
    },
    putCustomArray(diffArray, settingName, settings) {
      if (diffArray.length > 0) {
        let customArray = []
        for (let i = 0; i < diffArray.length; i++) {
          let key = diffArray[i];
          let value = settings[key];
          customArray.push({
            key: key,
            value: value
          })
        }
        this.customSettings[settingName] = customArray
      }
    },
    getDiffArray(obj1, obj2) {
      let diffArray = []
      for (let key in obj1) {
        let obj1value = obj1[key];
        let obj2value = obj2[key];
        if (obj1value !== obj2value) {
          diffArray.push(key)
        }
      }
      return diffArray
    },
    refreshPlayerList(detailed) {
      if (this.gameId === -1) {
        return;
      }
      this.teamOnePlayerList = []
      this.teamTwoPlayerList = []
      this.playerListLoading = true;
      batchQueryServerPlayerApi(this.gameId, null, detailed).then((res) => {
        this.playerListInitialized = true
        let teamOnePlayerList = []
        let teamTwoPlayerList = []
        let teamQueuePlayerList = []
        let teamSpectatorPlayerList = []
        for (let i = 0; i < res['data']['data'].length; i++) {
          let player = res['data']['data'][i];
          if (player.teamId === 1) {
            teamOnePlayerList.push(player)
          }
          else if (player.teamId === 2) {
            teamTwoPlayerList.push(player)
          }
          else if (player.teamId === 3) {
            teamQueuePlayerList.push(player)
          }
          else if (player.teamId === 4) {
            teamSpectatorPlayerList.push(player)
          }
        }
        this.sortByRank(teamOnePlayerList);
        this.sortByRank(teamTwoPlayerList);
        this.teamOnePlayerList = teamOnePlayerList;
        this.teamTwoPlayerList = teamTwoPlayerList;
      }).finally(() => {
        this.playerListLoading = false;
      })
    },
    refreshMemberList() {
      if (this.gameId === -1) {
        return;
      }
      this.refreshAdminList()
      this.refreshVipList()
      this.refreshBanList()
    },
    // 显示玩家列表
    displayPlayerListDialog() {
      this.playerListVisible = true;
      this.refreshPlayerList(true);
    },
    // 显示管服面板
    displayManagePanel() {
      this.managePanelVisible = true;
      if (!this.playerListInitialized) {
        this.refreshPlayerList(false);
      }
      if (!this.memberListInitialized) {
        this.refreshMemberList();
      }
    },
    // 刷新管理员列表
    refreshAdminList() {
      if (this.gameId === -1) {
        return;
      }
      this.adminList = []
      this.adminListLoading = true
      batchQueryServerAdminApi(this.gameId, null).then((res) => {
        this.memberListInitialized = true;
        this.adminList = res['data']['data']
      }).finally(() => {
        this.adminListLoading = false
      })
    },
    // 刷新vip列表
    refreshVipList() {
      if (this.gameId === -1) {
        return;
      }
      this.vipList = []
      this.vipListLoading = true
      batchQueryServerVipApi(this.gameId, null).then((res) => {
        this.memberListInitialized = true;
        this.vipList = res['data']['data']
      }).finally(() => {
        this.vipListLoading = false
      })
    },
    // 刷新ban列表
    refreshBanList() {
      if (this.gameId === -1) {
        return;
      }
      this.banList = []
      this.banListLoading = true
      batchQueryServerBanApi(this.gameId, null).then((res) => {
        this.memberListInitialized = true;
        this.banList = res['data']['data']
      }).finally(() => {
        this.banListLoading = false
      })
    },
    // 等级排序
    sortByRank(playerList) {
      if (playerList !== null && playerList.length > 0) {
        let compare = function (o1, o2) {
          if (o1['rank'] === null || o1['rank'] === undefined) return 1;
          if (o2['rank'] === null || o2['rank'] === undefined) return -1;
          if (o1['rank'] > o2['rank']) return -1;
          else if (o1['rank'] < o2['rank']) return 1;
          else return 0;
        }
        playerList.sort(compare)
      }
    },
    // 处理确认信息
    handleTarget() {
      this.type[this.targetType]?.func()
    },
    // 显示确认弹窗
    displayConfirmDialog(personaRank, personaName, personaId, teamId, type) {
      this.targetPersonaRank = personaRank;
      this.targetPersonaName = personaName;
      this.targetPersonaId = personaId;
      this.targetTeamId = teamId
      this.targetType = type;
      this.confirmFormVisible = true
    },
    // 加ban
    addBan() {
      if (this.gameId === -1) {
        return;
      }
      this.processing = true;
      addPlayerBanApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName, this.targetReason).then(() => {
        this.refreshBanList()
        this.$notify({
          title: '成功',
          message: '封禁 ' + this.targetPersonaName + ' 成功',
          type: 'success'
        });
        this.confirmFormVisible = false
      }).finally(()=>{
        this.processing = false;
      })
    },
    // 加vip
    addVip() {
      if (this.gameId === -1) {
        return;
      }
      this.processing = true;
      addPlayerVipApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName).then(() => {
        this.refreshVipList()
        this.$notify({
          title: '成功',
          message: '添加VIP ' + this.targetPersonaName + ' 成功',
          type: 'success'
        });
        this.confirmFormVisible = false
      }).finally(()=>{
        this.processing = false;
      })
    },
    removeVip() {
      if (this.gameId === -1) {
        return
      }
      this.processing = true;
      removePlayerVipApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName).then(() => {
        this.refreshVipList()
        this.$notify({
          title: '成功',
          message: '移除VIP ' + this.targetPersonaName + ' 成功',
          type: 'success'
        });
        this.confirmFormVisible = false
      }).finally(()=>{
        this.processing = false;
      })
    },
    removeBan() {
      if (this.gameId === -1) {
        return
      }
      this.processing = true;
      removePlayerBanApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName).then(() => {
        this.refreshBanList()
        this.$notify({
          title: '成功',
          message: '移除封禁 ' + this.targetPersonaName + ' 成功',
          type: 'success'
        });
        this.confirmFormVisible = false
      }).finally(()=>{
        this.processing = false;
      })
    },
    mapSwitch(index) {
      if (this.gameId === -1) {
        return
      }
      if (!this.hasPermission) {
        return;
      }
      this.processing = true;
      switchMapApi(this.gameId, null, index).then(() => {
        this.$notify({
          title: '成功',
          message: '已切换至地图 ' + this.serverInfo['rotations'][index]['map']['name'] + '[' + this.serverInfo['rotations'][index]['mode']['name'] + ']',
          type: 'success'
        });
        setTimeout(()=>{
          this.refreshServerInfo()
        }, 1000)
      }).finally(() => {
        this.processing = false;
      })
    },
    move() {
      if (this.gameId === -1) {
        return
      }
      this.processing = true;
      movePlayerApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName, this.targetTeamId).then(() => {
        this.$notify({
          title: '成功',
          message: '移动 ' + this.targetPersonaName + ' 成功',
          type: 'success'
        });
        this.confirmFormVisible = false
        setTimeout(()=>{
          this.refreshPlayerList(false)
        }, 1000)
      }).finally(()=>{
        this.processing = false;
      })
    },
    kick() {
      if (this.gameId === -1) {
        return
      }
      this.processing = true;
      kickPlayerApi(this.gameId, null, this.targetPersonaId, this.targetPersonaName, this.targetReason).then(() => {
        this.$notify({
          title: '成功',
          dangerouslyUseHTMLString: true,
          message: ('移除 ' + this.targetPersonaName + ' 成功') + (this.targetReason ? '<br>原因：' + this.targetReason : ''),
          type: 'success'
        });
        this.confirmFormVisible = false
        setTimeout(()=>{
          this.refreshPlayerList(false)
        }, 1000)
      }).finally(()=>{
        this.processing = false;
      })
    },
    handleBack() {
      if (window.history.length <= 1) {
        window.close()
      }
      else {
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped>
/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
  color: white;
}

/* 地图轮换鼠标移入 */
.mapItem {
  &:hover {
     .mapImage {
       transform: translateY(-10px);
     }
    .mapContent {
      background-color: white;
      color: #151515;
      font-weight: bold;
    }
  }
}
.mapContentHover {
  width: 100%;
  height: 50%;
  position: absolute;
  z-index: 50;
  bottom: 0;
  opacity: 1;
  transition-duration: 200ms;
  background-color: white;
  color: #151515;
  font-weight: bold;
}
/* 地图图片 */
.mapImage {
  transition-duration: 200ms;
}
/* 地图鼠标移入后提示器 */
.mapContent {
  background-color: #151515;
  width: 100%;
  height: 50%;
  position: absolute;
  z-index: 50;
  bottom: 0;
  opacity: 1;
  transition-duration: 200ms;
}

.changeMapList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.changeMapList::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}

.whiteBackgroundHover:hover {
  background-color: white;
  color: #4f4f4f;
}

::v-deep .manager-dialog.el-dialog {
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}

::v-deep .el-dialog__header {
    background-color: #343434;
  padding: 10px
}
::v-deep .el-dialog__title {
  color: white;
}
::v-deep .el-dialog__body {
  background-color: #111111;
}
::v-deep .el-dialog__footer {
  background-color: #111111;
}

::v-deep .ant-tabs-ink-bar {
  background-color: white;
}
::v-deep .ant-tabs-tab {
  padding-top: 0;
}
::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
  color: white;
}
::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: white;
}

::v-deep .ant-tabs-bar {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
::v-deep .ant-tabs {
  color: white;
}
::v-deep .ant-tabs-nav-container {
  font-size: 1.2em;
}

/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled]:hover, .hoverButton.ant-btn[disabled]:focus {
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}

::v-deep .ant-descriptions-item-label {
  color: white;
}
::v-deep .ant-descriptions-item-content {
  color: white;
}

::v-deep .el-loading-spinner .el-loading-text {
  color: white;
}
::v-deep .el-loading-spinner .path {
  stroke: white;
}

/** <576px **/
@media screen and (max-width: 576px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 15px;
  }
}
/** ≥576px **/
@media screen and (min-width: 576px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 15px;
  }
}
/** ≥768px **/
@media screen and (min-width: 768px) {
  ::v-deep .el-dialog {
    width: 90%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 16px;
  }
}
/** ≥992px **/
@media screen and (min-width: 992px) {
  ::v-deep .el-dialog {
    width: 80%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 17px;
  }
}
/** ≥1200px **/
@media screen and (min-width: 1200px) {
  ::v-deep .el-dialog {
    width: 75%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 18px;
  }
}
/** ≥1600px **/
@media screen and (min-width: 1600px) {
  ::v-deep .el-dialog {
    width: 60%;
  }
  ::v-deep .ant-tabs-tab {
    font-size: 18px;
  }
}
</style>